<template>
  <Modal class="sms" @close="$emit('close')">
    <template #title>Отправить СМС</template>
    <template>
      <PhoneInput v-model="sms.phone" @blur="sms.phone = $event"/>
      <Textarea v-model="sms.text" :fitContent="true" :rows="3" class="smsTextarea" placeholder="Введите текст СМС"/>
      <button :disabled="sendSMSdisabled" class="button fitWidth sms__send" @click="sendSMS">
        Отправить
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from '../ui/Modal';
import PhoneInput from '../ui/input/PhoneInput';
import Textarea from '../ui/input/Textarea';
import {mapActions} from 'vuex';

export default {
  name: 'SmsModal',
  components: {Textarea, Modal, PhoneInput},
  props: {
    data: Object,
  },
  data() {
    return {
      sms: {
        phone: this.data.phone,
        text: '',
      },
      sendSMSdisabled: false,
    }
  },
  methods: {
    ...mapActions(['postViaApiSmsByUrl']),
    async sendSMS() {

      let t = this;
      if (this.sms.phone && this.sms.text && !this.sendSMSdisabled) {
        this.sendSMSdisabled = true;
        this.sms.text = this.sms.text.substring(0, 268);
        let r = await this.postViaApiSmsByUrl({url: '', data: this.sms})
          .catch(
            e => alert('При отправке сообщения произошла ошибка: ' + e)
          );

        if (r.data.status === 200) {
          t.sms.text = '';
          alert('Сообщение успешно отправлено!');
        } else {
          alert('При отправке сообщения произошла ошибка: ' + r.data.status + ', ' + r.data.message)
        }
        this.sendSMSdisabled = false;
      } else {
        alert('Введите текст сообщения и валидный номер для отправки');
      }

    }
  }
}
</script>

<style lang="less" scoped>
.moves /deep/ .modal {
  height: fit-content;
}

.table, .right {
  margin: 10px 0;
}

.button {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  --color: @red;
  position: relative;
  font-family: inherit;
  margin: 10px 10px 10px 10px;
  color: white;
  cursor: pointer;
  background-color: var(--color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-left: auto;
}

.button:disabled {
  cursor: not-allowed;
  --color: @cold-grey;

}

.subtitle {
  color: @cold-grey;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 0.75em;
  margin-bottom: 10px;
  margin-top: 10px;
}

.smsTextarea {
  height: auto;
}

.fitWidth {
  width: fit-content !important;
}
</style>
