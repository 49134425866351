<template>
  <Modal @close="closeWindow">
    <template slot="title">Переадресация звонка</template>
    <div style="display: flex; flex-direction:column; height:100%; align-items:center">
      <div class="transfer-groups">
        <div v-for="(group, index) in transferGroups"
             :key="index"
             class="transfer-groups-item"
             v-bind:class="{selected: selectedGroup && group.id === selectedGroup.id}"
             @click="selectGroup(group)">{{ group.name }}
        </div>

      </div>
      <div class="transfer-groups-footer">
        <Button :disabled="!this.selectedGroup" square @click="makeTransfer">OK</Button>
      </div>
    </div>
  </Modal>
</template>

<script>

import Modal from '../ui/Modal';
import {mapActions, mapMutations, mapState} from 'vuex';
import Button from '../ui/input/Button.vue';

export default {
  name: 'TransferCall',
  components: {Modal, Button},
  data() {
    return {
      selectedGroup: null,
      sipInfo: {},
      groups: [
        {id: 1, name: 'Юристы', alias: 'group2'},
        {id: 2, name: 'Пресс-служба', alias: 'group3'}
      ]
    }
  },
  computed: {
    ...mapState({
      phoneState: state => state.call.phoneState,
      sipSession: state => state.call.sipSession,
      transferGroups: state => state.call.transferGroups
    }),

  },
  methods: {
    ...mapActions({setSession: 'call/setSession'}),
    ...mapMutations({transferCall: 'call/setTransferCall', setTransferDestination: 'call/setTransferDestination'}),
    selectGroup(group) {
      this.selectedGroup = group
    },
    makeTransfer() {
      this.setTransferDestination(this.selectedGroup);
      this.$sip.transferCall(this.selectedGroup.alias)
    },
    closeWindow() {
      this.transferCall(false)
    },

  },
  watch: {
    phoneState(val, oldval) {
      console.log(`TransferCall: phoneState watcher starting: ${oldval} -> ${val}`);
      if (val !== 'call-in-progress')
        this.closeWindow();
    }
  }
}
</script>

<style lang="less" scoped>

.transfer-groups {
  display: block;
  flex-direction: column;
  align-items: center;
  position: absolute;
  overflow-y: scroll;
  height: 300px;
  top: auto;
  left: 0;
  width: 100%;

  &-item {

    width: 100%;
    line-height: 4em;
    padding-left: 20px;
    font-size: 15px;

    &::after {
      content: '';
      border-bottom: 1px solid #EBEDF3;
      display: block;
      width: 100%;
      position: absolute;
      top: auto;
      left: 0;
    }

    &:hover, &:active, &-selected {
      background-color: #EBEDF3;
    }
  }

  &-footer {
    margin-top: auto;
    margin-bottom: 20px;
  }

  .selected {
    background-color: #EBEDF3;
  }

}

</style>
