import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import call from './modules/call';
import session from './modules/session'
import info from './modules/info'
import dictionaries from './modules/dictionaries';
import police from './modules/police';
import event from './modules/event';
import city from './modules/city';
import ui from './modules/ui';
import audio from './modules/audio';
import api from './modules/api';
import api_search from './modules/api_search';
import api_session from './modules/api_session';
import api_clientpack from './modules/api_clientpack';
import api_court_patch from './modules/api_court_patch';

import sharedMutations from 'vuex-shared-mutations'
import createMultiTabState from 'vuex-multi-tab-state'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth, session, info, call, dictionaries, ui, police, event, city, audio,
    api_search, api_session, api_clientpack, api_court_patch, api
  },
  plugins: [createPersistedState({
    paths: ['auth', 'ui', 'police', 'event', 'city', 'dictionaries']
  }),
  sharedMutations({predicate: ['call/setRegstredTabId']}),
  createMultiTabState({statesPaths: ['call.onlineTabId', 'call.registredTabId']})]
});
