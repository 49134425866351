<template>
  <div class="sip-controls">
    <div class="test-call">
      <button v-if="sipRegistred && !sipConnecting && !hasCall" data-tooltip="Проверка аудио"
              data-tooltip-position="bottom" @click="startTestCall">
        <Icon name="phone-small"/>
      </button>
    </div>
    <div class="on-line">
      <Checkbox 
        :warn="sipReconnecting || (sipRegistred && hasCall && (phoneState === 'idle' || phoneState === 'incoming'))"
        :checked="sipRegistred || sipReconnecting"
        :readonly="sipConnecting"
        @click="tryConnect">
        На линии
      </Checkbox>

      <div v-if="!sipConnecting && !sipRegistred && !(sipRegistredTabId && !sipRegistred)" style="color:transparent">
        ...
      </div>
      <div v-if="sipConnecting && sipConnectionRequested" class="warn">Подключение...</div>
      <div v-if="sipConnecting && !sipConnectionRequested" class="warn">Отключение...</div>
      <div v-if="sipRegistred && !sipConnecting" class="call-queue">Звонков в очереди: {{ callQueue.length }}</div>
      <div v-if="sipRegistredTabId && !sipRegistred && !sipReconnecting && !sipConnecting" class="warn">Вы уже вышли на линию в другой вкладке браузера</div>
      <div v-if="!sipConnecting && !sipRegistred && sipReconnecting" class="warn">Соединение отсутсвует</div>
    </div>
  </div>
</template>

<script>
import Checkbox from '../ui/input/Checkbox';
import Icon from '../ui/Icon';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import throttle from 'lodash/throttle'
import NoSleep from 'nosleep.js';

export default {
  name: 'SipControl',
  components: {Checkbox, Icon},
  data() {
    return {
      dummyStream: null,
      noSleep: new NoSleep()
    }
  },
  methods: {
    ...mapActions({
      sipConnect: 'call/sipConnect',
      sipDisconnect: 'call/sipDisconnect',
      sipReconnect: 'call/sipReconnect',
      initTestCall: 'call/initTestCall'
    }),
    ...mapMutations({setRegstredTabId: 'call/setRegstredTabId', setSipRegstred:'call/setRegistered'}),

    async tryConnect(value) {
      value.preventDefault();

      if (!this.sipRegistred && !this.sipReconnecting) {
        if (this.onlineTabId) {
          this.$notify(({
            type: 'error',
            title: 'Невозможно выйти на линию',
            text: 'Есть активная сессия в другой вкладке, завершите её и попробуйте снова.'
          }));
          return
        }
        this.noSleep.enable();
        await this.sipConnect()
      } else {
        if (this.onlineTabId && this.onlineTabId === this.currentTabId) {
          this.$notify(({
            type: 'error',
            title: 'Невозможно уйти с линии',
            text: 'Есть активная сессия, завершите её и попробуйте снова.'
          }));
          return
        }

        if(this.sipReconnecting)
          this.setRegstredTabId(null);

        this.setAudio(false);
        this.noSleep.disable();
        await this.sipDisconnect();
      }
    },
    startTestCall() {
      this.startTestCallWithThrottle(this)
    },
    startTestCallWithThrottle: throttle((vm) => {
      vm.initTestCall()
    }, 5000, {'trailing': false}),

    async setAudio(val){
      if(val){
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          this.dummyStream = await navigator.mediaDevices.getUserMedia(
            {
              audio: true
            })
        } else {
          throw new Error('getUserMedia not supported on your browser!');
        }
      } else {
        if(this.dummyStream){
          const tracks = this.dummyStream.getAudioTracks();
          tracks[0].stop();
          this.dummyStream = null;
        }
      }
    }
      
  },
  computed: {
    ...mapState({
      sipConnectionRequested: state => state.call.connectionRequested,
      sipConnecting: state => state.call.sipConnecting,
      sipReconnecting: state => state.call.sipReconnecting,
      sipRegistred: state => state.call.registered,
      sipRegistredTabId: state => state.call.registredTabId,
      currentTabId: state => state.call.currentTabId,
      phoneState: state => state.call.phoneState,
      callQueue: state => state.call.callQueue,
      onlineTabId: state => state.call.onlineTabId
    }),
    ...mapGetters({
      hasCall: 'call/hasCall'
    }),


  },
  watch: {
    sipRegistredTabId(val) {
      if (val !== this.currentTabId) {
        this.sipDisconnect();
      }
    },
    async sipRegistred(val) {
      if (val) {
        this.setAudio(true);
      } else {

        if (this.currentTabId === this.sipRegistredTabId) {
          if(this.sipConnectionRequested){
            this.sipReconnect();
          } else {
            this.setRegstredTabId(null);
          }
        } else {
          this.setAudio(false);
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.sip-controls {
  margin-left: auto;
  display: flex;
  flex-direction: row;

  .test-call {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      border: none;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
      color: white;
      cursor: pointer;
      background-color: @green;
      position: relative;
      font-family: inherit;
      margin-right: 8px;
      padding: 8px;
    }
  }

  .on-line {
    margin-left: auto;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .call-queue {
      font-size: 0.55em;
      color: #C5C7D9;
      margin-top: 0.5em;
    }

    .warn {
      font-size: 0.55em;
      color: #D6540B;
      margin-top: 0.5em;
    }
  }
}

</style>
