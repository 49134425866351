<template>
  <Modal class="phones" @close="closeAction">
    <template #title>Телефоны</template>

    <template>
      <div class="phones__list">
        <div v-for="phone in data.list" :key="phone.phone_number" class="phone">

          <span class="phone__number">
            <span :class="{'errorPhone': data.error && data.last_error_phone_number.includes(phone.phone_number)}"
                  class="phone__number">{{
              formatPhone(phone.phone_number)
            }}</span>

          <button class="circle-btn phone__call" data-tooltip="Позвонить"
                  data-tooltip-small @click="call(phone.phone_number)"
          >
            <Icon name="outbound-call"/>
          </button>
          </span>


          <span v-if="phone.comment && editingComment !== phone" :title="phone.comment" class="phone__comment"
                @click="editComment(phone)">{{ phone.comment }}</span>

          <TextInput v-else-if="editingComment === phone"
                     v-model="phone.comment" class="phone__comment" placeholder="Комментарий"
                     @blur="editPhone(phone)"/>

          <span v-else class="phone__comment hint" @click="editComment(phone)"
          >Введите комментарий</span>

          <span class="phone__remove" data-tooltip="Удалить номер телефона"
                data-tooltip-small @click="removePhone(phone)">
            <Icon v-if="edit" name="close"/>
          </span>


        </div>
        <div v-if="edit" class="phone new">
          <PhoneInput :key="inputKey" :value="newPhone.phone_number" class="phone__number"
                      @blur="addedPhone = $event; editingPhone = false;"
                      @focus="phoneFocus"
                      @phoneInvalid="newPhone.phone_number = $event;"/>
          <TextInput v-model="newPhone.comment" class="phone__comment" placeholder="Комментарий"/>
          <div
              data-tooltip="Сохранить номер"
              data-tooltip-small v-bind:class="['circle-btn-secondary', { 'disabled': !addedPhone || addedPhone === 'INVALID'}, 'phone__add']" @click="addPhone">
            <Icon name="check"/>
          </div>

        </div>
        <div v-if="showError" class="phone new not-found">
          {{ errorText }}
        </div>
        <div class="other">
          <div class="other__subtitle">Другие контакты</div>
          <div>
            <TextareaEditable
              placeholder="Другие контакты"
              :link-mode="true"
              :value="data.other_contacts"
              @blur="change($event)"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../ui/Modal';
import Icon from '../ui/Icon';
import TextInput from '../ui/input/TextInput';
import TextareaEditable from '../ui/input/TextareaEditable';
import PhoneInput from '../ui/input/PhoneInput';
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'PhonesModal',
  components: {TextInput, Icon, Modal, PhoneInput, TextareaEditable},
  props: {
    data: Object,
    edit: Boolean,
  },
  data() {
    return {
      newPhone: {
        phone_number: null,
        comment: ''
      },
      editingComment: null,
      addedPhone: '',
      editingPhone: null,
      inputKey: null,
      errorText: 'Телефон не прошел валидацию.',
      showError: false,
    }
  },
  mounted() {
    this.inputKey = this.randomId();
  },
  computed: {
    ...mapGetters({
      hasCall: 'call/hasCall'
    }),
    ...mapState({
      sipRegistred: state => state.call.registered
    }),
  },
  methods: {
    ...mapActions('call', ['initOutgoingCall']),
    closeAction() {
      let result = true;

      if (this.newPhone.phone_number || this.newPhone.comment !== '') {
        result = confirm('Вы уверены? Данные не сохранятся!')
      }

      if (result) {
        this.$emit('close');
      }
    },
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    phoneFocus() {
      this.showError = false;
      this.editingPhone = true;
    },
    change(event) {
      this.$emit('phone', 'other_contacts', this.data.id, event);
    },
    editComment(phone) {
      this.editingComment = phone;
    },
    formatPhone(number) {
      return number//IMask.createMask({mask: '+{7} (000) 000-00-00'}).resolve(number) ?? number
    },
    removePhone(phone) {
      if (confirm('Вы уверены, что хотите удалить?')) {
        this.$emit('phone', 'remove', this.data.id, phone);
      }
      return phone
    },
    editPhone(phone) {
      this.$emit('phone', 'edit', this.data.id, phone);
      this.editingComment = null;
    },
    addPhone() {
      if (this.addedPhone !== 'INVALID' && this.addedPhone !== '') {
        this.showError = false;
        this.newPhone.phone_number = this.addedPhone;

        if (!this.data.list || !this.data.list.map(a => a.phone_number).includes(this.newPhone.phone_number)) {
          this.$emit('phone', 'add', this.data.id, this.newPhone);

          this.newPhone = {
            phone_number: null,
            comment: ''
          };
          this.addedPhone = '';
        } else {
          alert('Введенный номер телефона уже был добавлен этому задержанному ранее')
        }
      } else {
        this.showError = true;
      }

      this.inputKey = this.randomId();
    },
    async call(number) {
      if (this.hasCall) {
        this.$notify(({
          type: 'error',
          title: 'Не удалось совершить звонок',
          text: 'Необходимо завершить текущую сессию'
        }))
        return
      }
      if (!this.sipRegistred) {
        this.$notify(({type: 'error', title: 'Не удалось совершить звонок', text: 'Необходимо выйти на линию'}));
        return
      }
      await this.initOutgoingCall(number);
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.phones /deep/ .modal {
  height: fit-content;
width: calc(100% - 160px);
}

.phones /deep/ .modal {
  &-content {
    padding: 20px;
    padding-top: 0;
  }
  &-header {
    padding: 20px 20px 10px 20px;
  }
  width: calc(100% - 60px);
  max-width: 682px;
}

.phones__list {
  width: 100%;
  margin: 0;
}

.phone {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  min-height: 50px;
  justify-content: space-between;.block {
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
}
  .flex {
    display: flex;
  }
  .flex.jsb {
    justify-content: space-between;
  }
  .flex.jc {
    justify-content: center;
  }
  .flex.ac {
    align-items: center;
  }
  [data-tooltip] {
    position: relative;
    /* Dynamic horizontal centering */
    /* Dynamic vertical centering */
  }
  [data-tooltip]::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: calc(100% + 8px);
    font-size: 0.8rem;
    white-space: nowrap;
    color: #2D2D2D;
    background-color: #FFFFFF;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 10px 16px;
    opacity: 0;
    pointer-events: none;
    transition-duration: 0.2s;
    z-index: 999999;
  }
  [data-tooltip][data-tooltip-position="top"]:before,
  [data-tooltip][data-tooltip-position="bottom"]:before {
    left: 50%;
    transform: translateX(-50%);
  }
  [data-tooltip][data-tooltip-position="right"]:before,
  [data-tooltip][data-tooltip-position="left"]:before {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
  [data-tooltip][data-tooltip-position="top"]:before {
    bottom: 100%;
    margin-bottom: 6px;
  }
  [data-tooltip][data-tooltip-position="right"]:before {
    left: 100%;
    margin-left: 6px;
  }
  [data-tooltip][data-tooltip-position="bottom"]:before {
    top: 100%;
    bottom: auto;
    margin-top: 6px;
  }
  [data-tooltip][data-tooltip-position="left"]:before {
    right: 100%;
    margin-right: 6px;
  }
  [data-tooltip][data-tooltip-small]::before {
    font-size: 0.75rem;
    padding: 8px 14px;
  }
  [data-tooltip]:hover::before {
    opacity: 1;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
  }
  .fade-leave-active {
    position: absolute;
  }
  .card-enter,
  .card-leave-to {
    opacity: 0;
    transform: translateY(-4px);
  }
  .card-enter-active {
    transition-duration: 0.5s;
  }
  .card-leave-active {
    transition-duration: 0.2s;
  }
  .not-found {
    color: #D6540B;
    font-size: 0.85em;
  }
  .not-found svg {
    height: 1.3em;
    margin-right: 0.4em;
    vertical-align: bottom;
  }
  .inputs > *:not(:last-child) {
    flex-basis: 0;
    flex-grow: 1;
  }
  #DetainedName_full_name {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }
  .subtitle {
    margin: 0 15px !important;
  }
  .date-input {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    flex: inherit !important;
  }

  &__remove, &__add, &__edit {
    width: 12px;
    display: block;
    color: @orange;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;

    /deep/ svg {
      width: 12px;
    }
  }

  &__add {
    color: @green-dark;
    //transform: rotate(45deg);
  }

  &__number {
    color: @green;
    white-space: nowrap;
  }

  &__comment {
    margin: 10px;
    font-size: .9rem;
    color: @cold-grey;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &__call {
    margin-left: 10px;
    min-width: 32px;
  }

  &__number {
    width: 11em;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
}

.circle-btn-secondary {
  color: @green;

  svg {
    height: 14px;
    width: auto;
  }

  &.disabled {
    color: @cold-grey;

  }
}

.circle-btn {
  background: none;
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .09);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @green;
  position: relative;
  cursor: pointer;

  svg {
    height: 14px;
    width: auto; 
  }
}

.other {
  width: 100%;
  padding: 0;
  min-height: 50px;

  label {
    width: 100%;
  }

  &__subtitle {
    display: block;
    color: @cold-grey;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 0.75em;
    padding: 10px 0!important;
  }
}

.hint {
  color: @cold-grey-light;
}

.errorPhone {
  border: red 1px solid;
}

.phones /deep/ .vti {
  &__flag {
    transform: scale(0.7);
  }
  &__dropdown{
    padding: 0 5px;
  }
  &__input {
    padding: 10px 10px 10px 0px;
    font-size: 0.8em;
  }


}

</style>
